// src/components/Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer className="footer bg-dark text-white py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-left">
                        © 2024 Vanlife.ma - Tous droits réservés
                    </div>
                    <div className="col-md-6 text-right">
                        Suivez-nous sur:
                        <lu>
                            <li><a href="#" className="text-white ml-2"><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
                            <li><a href="#" className="text-white ml-2"><FontAwesomeIcon icon={faFacebookF} /> Facebook</a></li>
                        </lu>
                        
                
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
