// src/components/Header.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMapSigns, faTools, faAddressBook, faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'; // Assurez-vous que le CSS est correctement importé

const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <a className="navbar-brand" href="#">Vanlife.ma</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <div className="navbar-nav mr-auto">
                    <a className="nav-item nav-link active" href="#"><FontAwesomeIcon icon={faHome} /> Home</a>
                    <a className="nav-item nav-link" href="#"><FontAwesomeIcon icon={faMapSigns} /> Itinéraires</a>
                    <a className="nav-item nav-link" href="#"><FontAwesomeIcon icon={faTools} /> Aménagements</a>
                    <a className="nav-item nav-link" href="#"><FontAwesomeIcon icon={faAddressBook} /> Contact</a>
                </div>
                <div className="navbar-nav ml-auto">
                    <a className="nav-item nav-link" href="#"><FontAwesomeIcon icon={faSignInAlt} /> Connexion</a>
                    <a className="nav-item nav-link" href="#"><FontAwesomeIcon icon={faUserPlus} /> Enregistrement</a>
                </div>
            </div>
        </nav>
    );
};

export default Header;
