import React, { useState } from 'react';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import LoginComponent from './components/LoginComponent';
import RegisterComponent from './components/RegisterComponent';

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  return (
    <div className="App">
      <Header />
      <HomePage />
      <Footer />
      <LoginComponent showModal={showLogin} handleClose={() => setShowLogin(false)} />
      <RegisterComponent showModal={showRegister} handleClose={() => setShowRegister(false)} />
    </div>
  );
}

export default App;
