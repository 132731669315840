// src/components/HomePage.js
import React from 'react';
import './Home.css'; // Assurez-vous que le chemin d'accès au CSS est correct

const HomePage = () => {
    return (
        <div className="home-container">
            <div className="background-blur"></div> {/* Div pour l'arrière-plan flou */}
            <div className="content">
                <h1 className="display-4">Vanlife.ma - Bientôt Disponible</h1>
                <p className="lead">La référence pour la van life au Maroc. Location de vans aménagés, aménagements, itinéraires et plus encore.</p>
                <hr className="my-4" />
                <p>Lancement prévu : <strong>Automne 2024</strong></p>
                <a className="btn btn-primary btn-lg" href="#" role="button">Découvrez nos offres</a>
            </div>
        </div>
    );
};

export default HomePage;
